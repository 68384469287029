import styles from './PostsHero.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'
import PostHeroMain from 'shared-components/posts/PostsHeroMain'
import PostsHeroSide from 'shared-components/posts/PostsHeroSide'

interface PostsHeroProps extends BasicStyledComponent {
  posts: readonly DSPostShortWithAuthor[]
}

const PostsHero: React.VFC<PostsHeroProps> = ({ posts, className }) => {
  const [main, ...sides] = posts
  return (
    <div className={clsx(styles.container, className)}>
      {main ? <PostHeroMain post={main} className={styles.main} /> : null}
      {sides.slice(0, 4).map((item, index) => (
        <PostsHeroSide
          post={item}
          key={index}
          className={clsx({
            [styles.tl]: index === 0,
            [styles.bl]: index === 2,
            [styles.tr]: index === 1,
            [styles.br]: index === 3,
          })}
        />
      ))}
    </div>
  )
}

export default PostsHero
