import styles from './CBFeatured.module.css'
import styleUtils from 'shared-components/styles/util.module.css'
import PostsHero from 'shared-components/posts/PostsHero'
import { DSCBFeatured } from 'shared-definitions/types'

interface CBFeaturedProps {
  data: DSCBFeatured
}

const CBFeatured: React.VFC<CBFeaturedProps> = ({ data }) => (
  <div className={styles.container}>
    <PostsHero className={styleUtils.mobileFull} posts={data.items} />
  </div>
)

export default CBFeatured
